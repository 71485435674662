@import 'shared';

.PlaceholderBackingCards {
  display: block;
  width: px(250);
  height: px(350);
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;

  .sideCard {
    display: block;
    width: px(250);
    height: px(350);
    position: absolute;
    top: 0;
    left: 0;
  }

  .mainCard {
    display: block;
    width: 100%;
    height: calc(100% + #{px(2)});
    position: absolute;
    top: px(-2);
    left: 0;
    background-color: pink;
    z-index: 8;
  }
}
