@import 'shared';

.LandingTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 10;

  .logoWrapper {
    width: px(20);
    margin-top: px(12);
    margin-bottom: px(8);
  }

  .title {
    @include font-style(h1);
    width: 100%;
    padding-left: px(15);
    padding-right: px(15);

    .titleLine {
      display: block;
      text-align: center;
    }
  }

  .hidden {
    opacity: 0;
    visibility: hidden;
  }
}
