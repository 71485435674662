@import 'shared';

.FeatureCardContainer {
  overflow: hidden;

  .inner {
    @include position-100(absolute);
    overflow: hidden;
    border-radius: $border-radius-lg;
    transform: translateZ(0); //Fix for Safari border-radius overflow: hidden bug

    &.borderRadius-md {
      border-radius: calc(#{$border-radius-lg} / 2);
    }

    &.borderRadius-sm {
      border-radius: $border-radius-sm;
    }
  }
}
