@import 'shared';

.Landing {
  @include full-size(fixed);
  min-height: unset;
  height: vh(100);
  overflow-y: hidden;

  .hero {
    position: relative;
    width: 100%;
    h1 {
      font-size: 40rem;
    }
  }

  .hiddenCallout {
    position: absolute;
    opacity: 0;
  }
}
