@import 'shared';

$width: px(190);
$max-width: px(331);
$height: px(190);
$max-height: px(300);

.WelcomeIntro {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: vh(100);
  overflow: hidden;
  position: relative;

  .cards {
    flex-grow: 1;
    position: relative;
  }

  .cardsContainer {
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
    width: $max-width;
    height: $max-height;
  }

  .card {
    border-radius: $border-radius-lg;
    overflow: hidden;
    position: absolute;
    left: calc(#{$max-width} / 2 - #{$width} / 2);
    top: 0;

    &:nth-child(1) {
      left: px(-140);
      .colorOverlay {
        background-color: $light-grey;
        opacity: 0;
      }
    }
    &:nth-child(3) {
      left: px(280);
      .colorOverlay {
        background-color: $medium-grey;
        opacity: 0;
      }
    }
    &:nth-child(6) {
      top: px(210);
      left: px(280);
      .colorOverlay {
        background-color: $grey;
        opacity: 0;
      }
    }
    &:nth-child(2) {
      z-index: 8;
    }
    &:nth-child(4) {
      top: px(210);
      left: px(-140);
    }
    &:nth-child(5) {
      top: px(320);
    }
  }

  .colorOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .image {
    img {
      display: block;
      height: 100%;
    }
  }

  .squareImage {
    width: $width;
    height: $height;
  }

  .rectangleImage {
    width: $width;
    height: $max-height;
  }
}
