@use "sass:math";
@import '../../styles/shared.scss';

.Interests {
  touch-action: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: px(20);
  padding-bottom: px(25);
  height: 100%;

  @media screen and (min-height: 650px) {
    padding-top: px(40);
    padding-bottom: px(50);
  }

  @media screen and (min-height: 750px) {
    padding-top: px(40);
    padding-bottom: px(60);
  }

  .heading {
    @include font-style(h1);
    pointer-events: none;
  }

  .interestsSection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .pagination {
    @include font-style(caption);
    margin-top: px(10);
    margin-bottom: px(10);
  }

  .featureCardsWrapper {
    flex-grow: 1;
    width: $feature-card-width;
    margin-top: px(20);
    margin-bottom: px(40);
  }

  .featureCard {
    margin: auto;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;

    .cardsStack {
      z-index: 0;
      height: 100%;
      width: 100%;
      position: relative;
      margin: auto;

      span {
        display: block;
        position: absolute;
        height: 100%;
        border-radius: $border-radius-lg;

        &:nth-child(1) {
          width: calc(100% - #{px(60)});
          top: px(-6);
          left: px(30);
          background-color: $medium-grey;
          z-index: 2;
        }

        &:nth-child(2) {
          width: calc(100% - #{px(120)});
          top: px(-10);
          left: px(60);
          background-color: $light-grey;
          z-index: 1;
        }
      }
    }

    .stackedFeature {
      position: absolute;
      top: 0;
      height: 100%;

      .tinderCard {
        height: 100%;
      }
    }

    .stackedFeatureOffsetTop {
      top: px(-1);
      height: calc(100% + #{px(2)});
    }
  }

  .cardQuestionOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: vh(100);
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;

    // > div {
    //   margin: px(141) auto 0;
    // }
  }

  .featureSelect {
    position: relative;
    top: -(math.div($circle-button-size, 2));
    z-index: 1;
  }

  .cta {
    // margin-bottom: px(30);
  }
}
