@import 'shared';

.InterestsSwipeButtons {

  .button {
    display: inline-block;
    position: relative;
    padding: 0;
    border-radius: px(50);
    width: px(44);
    height: px(44);
    background-color: $white;
    box-shadow: 0 #{px(2)} #{px(3)} rgba($black, 0.15);
    border: none;
    outline: none;
    transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;

    &:first-child {
      margin-right: px(20);
    }
  }

  .selectedButton--select {
    background-color: $green;
    transform: scale(1.4);
  }

  .selectedButton--discard {
    background-color: $red;
    transform: scale(1.4);
  }

  .label {
    @include font-style(body-bold);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
  }

  .visibleLabel {
    animation-name: fadeIn;
    animation-duration: 200ms;
    animation-fill-mode: forwards;
    animation-delay: 200ms;
  }

  .svg {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: px(1);
  }

  .invisibleSvg {
    animation-name: fadeOut;
    animation-duration: 200ms;
    animation-fill-mode: forwards;
  }

  .svgClose {
    width: px(18);
    height: px(18);
  }

  .svgCheckmark {
    width: px(18);
    height: px(18);
  }

  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
  }
}
