@import 'shared';

$front-padding: px(12);

.FeatureCardFront {
  user-select: none;
  height: 100%;

  .textContainer {
    position: absolute;
    top: $front-padding;
    left: $front-padding;
    width: calc(100% - #{$front-padding * 2});
    border-radius: $border-radius-md;
    background-color: rgba($white, 0.8);
    z-index: 2;
    padding: px(20) px(15);
    text-align: left;
  }

  .title {
    @include font-style(h1);
    margin-bottom: px(5);
  }

  .description {
    @include font-style(body-bold);
  }

  .image {
    @include position-100(absolute);
  }
}
